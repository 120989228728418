[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='checkbox']:checked + label,
[type='checkbox']:not(:checked) + label {
  position: relative;
  padding-right: 55px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type='checkbox']:checked + label:before,
[type='checkbox']:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #666;
  background: #fff;
}

[type='checkbox']:checked + label:after,
[type='checkbox']:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #222222;
  position: absolute;
  top: 6px;
  right: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='checkbox']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
