.ai-assist-home-popup {
  background: transparent;
  width: 640px;
  margin-top: 40px
}

.ai-assist-home-popup .search-bar {
  background: #fff;
  /*opacity: 0.95;*/
  width: 640px;
}

.ai-assist-home-popup .search-bar .wand-icon {
  position: absolute;
  margin-top: 7px;
  margin-left: 4px;
}

.search-bar input, .search-bar input:focus {
  border-radius: 8px;
  box-shadow: 0 1px 20px 0 #00000033;
  border: 1px solid #E3E5E9;
  height: 36px;
  outline: none !important;
  width: 640px;
  padding-left: 32px;
  padding-right: 4px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.tools-list-menu {
  margin-top: 10px;
  background-color: #fff;
}

.tools-list-menu .szh-menu {
  width: 260px;
  border: 1px solid #E3E5E9;
  box-shadow: 0px 4px 20px 0px #00000033;
}

.tools-list-menu .szh-menu .menu-sub-header {
  color: #737E93;
  font-size: 13px;
  padding: 8px;
}

.tools-list-menu .szh-menu__item {
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #3C424C;
  font-size: 14px;
}

.tools-list-menu .szh-menu__item .item-title {
  padding-left: 12px;
}

.tools-list-menu .szh-menu__item .svg-icon {

}


/***************************************************************/
.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  color: black;
}

.ai-assist-generating {
  background: #fff;
  width: 640px;
  box-shadow: 0 1px 20px 0 #00000033;
  min-height: 200px;
  border: 1px solid #E3E5E9
}

.ai-assist-generating .gen-message {
  padding: 20px;
  color: #8F98A9;
  max-height: 200px;
  text-align: justify;
  overflow-y: scroll;
}

.ai-assist-generating .ai-footer{
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  background: linear-gradient(0deg, #F8F9FA, #F8F9FA), linear-gradient(0deg, #E3E5E9, #E3E5E9);
  padding-left: 8px;
  margin-top: auto;
  color: #8F98A9;
}
/***************************************************************/
.action-links{
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.action-links .action{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
