.fs-10{font-size: 10px}
.fs-12{font-size: 12px}
.fs-14{font-size: 14px}
.fs-16{font-size: 16px}
.fs-18{font-size: 18px}
.fs-20{font-size: 20px}
.fs-22{font-size: 22px}
.fs-24{font-size: 24px}
.fs-26{font-size: 26px}
.fs-28{font-size: 28px}
.fs-30{font-size: 30px}
.fs-32{font-size: 32px}
.fs-34{font-size: 34px}
.fs-36{font-size: 36px}
.fs-38{font-size: 38px}
.fs-42{font-size: 42px}
.fs-44{font-size: 44px}
.fs-46{font-size: 46px}
.fs-48{font-size: 48px}
.fs-52{font-size: 52px}
.fs-54{font-size: 54px}

.z-999{z-index: 999}

.h-fit-screen{min-height: calc(100vh - 64px);}

.h-full-screen{min-height: calc(100vh - 5px);}

/********************* DASHBOARD SYTLES *********************/
.file-folder-item .action-pane{
  display: none;
}
.file-folder-item:hover .action-pane{
  display: block;
  margin-left: auto;
}


/*----------------------------------------------------------*/
